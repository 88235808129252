import React, { createContext, FormEvent, useReducer } from "react";
import { Card, CardBody } from "reactstrap";
import FullCalendar, { EventClickArg, EventDropArg } from "@fullcalendar/react";
import listGridPlugin from "@fullcalendar/list";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import reducer, { actions, initialState, IStateCalendar } from "./reducer";
import { plainToClass } from "class-transformer";
import { Appointment } from "../../types/Appointment";
import {
  capitalize,
  convertDateToNumberDate,
  convertNumberAndTimeToDate,
  convertNumberDateToDate,
  getFormatNumber,
} from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import useAsyncEffect from "../../hooks/useAsyncEffect";
import {
  deleteAppointmentService,
  getAllAppointmentsService,
  saveAppointmentService,
  updateAppointmentService,
} from "../../services/backend/AppointmentService";
import { State } from "../../types/State";
import PopEvent, { buttonElementClick } from "./PopEvent";
import { EventCalendar } from "../../types/Calendar";
import { Account, SalesAdresseeAccount } from "../../types/Account";
import { getListModelProcess } from "../../services/backend/ProcessService";
import useTranslate from "../../hooks/useTranslate";
import { actionsPermissions } from "../../store/Permission/Slice";
import async from "async";
import { getSalesAddresseeByAccountService } from "../../services/backend/SalesAdresseService";
import { ProcessModel } from "../../types/models/processModel/Process";
import { Response } from "../../types/Response";
import { Permissions } from "../../types/Permissions";
import Permission from "../AuthorityPermission/Permission";
import { RootState } from "../../store/Reducers";

const permission = Permissions.CALENDAR;

export const CalendarContext = createContext<{
  state: IStateCalendar;
  onChangeEvent: (field: string, value: any) => void;
  account?: Account;
}>({
  state: initialState,
  onChangeEvent: (field, value) => null,
});

const CalendarComponent = (props: { users?: number[] }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((root: RootState) => root.AuthReducer);
  const [state, dispatchLocal] = useReducer(reducer, initialState);
  const { t } = useTranslate("calendar");
  const { event } = state;

  const getAppointments = async () => {
    const res = await getAllAppointmentsService();
    if (res.success) {
      dispatchLocal(actions.updateEvents(res.items));
    }
  };

  async function getSalesAddresseeByAccount(accountKey: string) {
    if (accountKey) {
      const sales: Response<SalesAdresseeAccount> =
        await getSalesAddresseeByAccountService(accountKey);
      dispatchLocal(actions.setSales(sales.items ?? []));
    }
  }

  async function getListModelProcessData() {
    const process: Response<ProcessModel> = await getListModelProcess();
    dispatchLocal(actions.setProcessModels(process.items ?? []));
  }

  useAsyncEffect(async () => {
    dispatch(actionsPermissions.getAllUsers());
    async.parallel([
      (cb) => getListModelProcessData().then((res) => cb(null, res)),
      (cb) => getAppointments().then((res) => cb(null, res)),
    ]);
  }, [dispatch]);

  useAsyncEffect(async () => {
    if (event?.data.account_data?.data) {
      await getSalesAddresseeByAccount(event.data?.account_data.data._KEY);
    } else {
      dispatchLocal(actions.setSales([]));
    }
  }, [event?.data.account_data]);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatchLocal(actions.setState(State.PENDING));
    if (event && user.id) {
      let res;

      if (event.data._KEY) {
        res = await updateAppointmentService(event.data);
      } else {
        const eventUser = { ...event.data, crm_user: user.id.toString() };
        res = await saveAppointmentService(eventUser);
      }

      if (res.success) {
        await getAppointments();
        buttonElementClick(convertDateToNumberDate(event.start));
      }
    }
  };

  async function deleteAppointment(e: EventCalendar) {
    dispatchLocal(actions.setState(State.PENDING));
    const res = await deleteAppointmentService(e.data._KEY);
    if (res.success) {
      await getAppointments();
      buttonElementClick(convertDateToNumberDate(e.start));
    }
  }

  function onChangeEvent(name: string, value: any) {
    if (event)
      dispatchLocal(
        actions.chaneEvent({
          ...event,
          data: {
            ...event.data,
            [name]: value,
          },
        }),
      );
  }

  const loading = state.state === State.PENDING;

  //Click in an event to update
  function updateEvent(e: EventClickArg) {
    const data = plainToClass(Appointment, e.event._def.extendedProps.data);
    const start = convertNumberAndTimeToDate(data.start_date, data.start_time);
    const end = convertNumberDateToDate(data.due_date);
    dispatchLocal(
      actions.chaneEvent({
        data: {
          ...data,
          start_time: getFormatNumber(start),
        },
        allDay: !!data.all_day,
        start,
        end,
        title: data.summary,
      }),
    );
    buttonElementClick(data.start_date.toString());
  }

  // Click in a day to add new event
  function newEvent(e: DateClickArg) {
    const data = plainToClass(Appointment, {
      start_date: convertDateToNumberDate(e.date),
      due_date: convertDateToNumberDate(e.date),
      start_time: getFormatNumber(e.date),
    });
    dispatchLocal(
      actions.chaneEvent({
        data,
        allDay: e.allDay,
        title: "",
        start: e.date,
      }),
    );
    buttonElementClick(convertDateToNumberDate(e.date));
  }

  //update event when drag and drop
  async function eventDrop(e: EventDropArg) {
    const data = plainToClass(Appointment, e.event._def.extendedProps.data);
    const start = convertNumberAndTimeToDate(data.start_date, data.start_time);
    start.setDate(start.getDate() + e.delta.days);
    start.setMonth(start.getMonth() + e.delta.months);
    start.setFullYear(start.getFullYear() + e.delta.years);
    start.setMilliseconds(start.getMilliseconds() + e.delta.milliseconds);
    if (start) {
      await updateAppointmentService({
        ...data,
        all_day: e.event._def.allDay,
        start_date: parseInt(convertDateToNumberDate(start)),
        start_time: getFormatNumber(start),
      });
      await getAppointments();
    }
  }

  return (
    <Permission permission={permission}>
      <CalendarContext.Provider value={{ onChangeEvent, state }}>
        <Card className="mb-2">
          <CardBody>
            <FullCalendar
              headerToolbar={{
                left: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                center: "title",
              }}
              dayCellContent={(hookProps) => {
                return (
                  <PopEvent
                    t={t}
                    onDelete={deleteAppointment}
                    numberDay={hookProps.dayNumberText}
                    id={convertDateToNumberDate(hookProps.date)}
                    onSubmit={onSubmit}
                  />
                );
              }}
              events={state.events}
              eventClick={updateEvent}
              dateClick={newEvent}
              eventContent={(e) => {
                const appo: Appointment = plainToClass(
                  Appointment,
                  e.event._def.extendedProps.data,
                );
                return (
                  <div title={appo.summary} className="overflow-auto bg-red">
                    <small>{capitalize(appo.summary)}</small>
                    <br />
                    <small>
                      {capitalize(
                        appo.account_data?.data.company.company_name.toLocaleLowerCase() ??
                          "",
                      )}
                    </small>
                  </div>
                );
              }}
              initialView="timeGridWeek"
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                listGridPlugin,
                interactionPlugin,
              ]}
              editable={!loading}
              selectable={!loading}
              monthMode
              droppable={!loading}
              eventDrop={eventDrop}
            />
          </CardBody>
        </Card>
      </CalendarContext.Provider>
    </Permission>
  );
};

export default CalendarComponent;
