import type { NextPage } from "next";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { authReducer } from "../store/Selectors";
import { AuthStates } from "../store/Auth/Types";
import { toMap } from "../utils";

export const START_PAGE_ON = "/inbox";
export const START_PAGE_PASSWORD_EXPIRATION = "/auth/update-password";
export const START_PAGE_LOGIN = "/auth/login";

const { version } = require("../package.json");

console.info("version ", version);
const Home: NextPage = () => {
  const { state, user } = useSelector(authReducer);
  const router = useRouter();

  useEffect(() => {
    const groups = user?.groups ?? [];
    const mapGroupsRoutes = toMap(
      user.config?.sbx_crm.start_page_by_groups ?? [],
      "id",
    );

    const group = groups.find((group) => mapGroupsRoutes[group.id.toString()]);
    //switch only for authenticated users use pattern matching
    switch (state === AuthStates.AUTHENTICATED) {
      //Available for the permission ROUTE_START only
      case !!group:
        const path = mapGroupsRoutes[group?.id?.toString() ?? ""];
        console.info("start page by permission: ", path.pathname);
        if (!path.pathname || path.pathname === "/") {
          router.push(START_PAGE_ON);
        } else {
          router.push(path.pathname);
        }
        break;

      /*IGNORE IF ROUTE START NOT EXIST*/

      //if the user has a start page configured, it will be redirected to that page and the ROUTE_START permission not assigned
      case !!user.config?.sbx_crm?.startPage:
        console.info("start page by config: ", user.config?.sbx_crm.startPage);
        router.push(user.config?.sbx_crm.startPage!);
        break;

      // default page
      default:
        console.info("default page: ", START_PAGE_ON);
        router.push(START_PAGE_ON);
        break;
    }
  }, [state]);

  return <div className="vh-35 bg-white shadow-lg" />;
};

export default Home;
