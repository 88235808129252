import {Column} from "./CustomTableComponent";
import {getCompoundName,} from "../../../utils";
import {ColumnsLabels} from "../../../types/Field";

interface TableProps {
    data: any[];
    columns: Column[];
    pageSize?: number;
    currentPage?: number;
}

export class TableCustom {
    private copyData: any[] = [];
    private data: any[] = [];
    private columns: Column[] = [];
    private pageSize: number = 15;
    private currentPage: number = 1;

    constructor(table: TableProps) {
        this.data = table.data;
        this.copyData = table.data;
        this.columns = table.columns;
        this.pageSize = table.pageSize ?? 15;
        this.currentPage = table.currentPage ?? 1;
    }

    public restoreTableData() {
        this.data = this.copyData;
    }

    public getAllTableData() {
        return this.copyData;
    }

    public getTableData() {
        return this.data;
    }

    public getTableColumns() {
        return this.columns;
    }

    public getPageSize() {
        return this.pageSize;
    }

    public getCurrentPage() {
        return this.currentPage;
    }

    public setTableData(data: any[]) {
        this.data = data;
    }

    public setTableColumns(columns: Column[]) {
        this.columns = columns;
    }

    public setPageSize(pageSize: number) {
        this.pageSize = pageSize;
    }

    public setCurrentPage(currentPage: number) {
        this.currentPage = currentPage;
    }

    public getTableDataByPage() {
        return this.data.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
    }

    public getTotalPages() {
        return Math.ceil(this.data.length / this.pageSize);
    }

    public goToPage(page: number) {
        this.currentPage = page;
    }

    public nextPage() {
        if (this.currentPage < this.getTotalPages()) {
            this.currentPage++;
        }
    }

    private getRowValue = ({
                               row,
                               header,
                               index,
                           }: {
        row: any;
        header: Column;
        index?: number;
    }) => {
        if (header) {
            try {
                if (header?.column_reference?.compound_name && row[header.name]) {
                    return (
                        getCompoundName({
                            columns: [header.column_reference] as any as ColumnsLabels[],
                            item: row[header.name],
                        }) ?? ""
                    );
                } else if (
                    header?.value &&
                    row[header.name] &&
                    row[header.name].hasOwnProperty(header.value)
                ) {
                    return row[header.name][header.value]
                } else if (
                    row[header.name] &&
                    (!header.value || header.type === "Reference")
                ) {
                    return row[header.name];
                } else if (
                    typeof row[header.name] === "number" &&
                    row[header.name] === 0
                ) {
                    return row[header.name]
                }

                return row[header.name];
            } catch (e) {
                return "";
            }
        }

        return "";
    };


    public columnItems(column: Column, slice?: number) {
        const uniqueItems = new Set<string>();

        for (const row of this.copyData) {
            const value = this.getRowValue({ row, header: column });
            uniqueItems.add(value);
        }

        const items = Array.from(uniqueItems);

        return slice ? items.slice(0, slice) : items;
    }


    public getColumnsContainsValues(columns?: Column[]) {
        return(columns ?? this.columns).filter((column) => this.columnItems(column).filter(value => typeof value === "number" || !!value).length > 0)
    }

    public previousPage() {
        if (this.currentPage > 1) {
            this.currentPage--;
        }
    }

    public firstPage() {
        this.currentPage = 1;
    }

    public lastPage() {
        this.currentPage = this.getTotalPages();
    }

    public getTotalByColumn(column: Column) {
        return this.data.reduce((acc, row) => {
            const value = this.getRowValue({ row, header: column });
            return acc + (typeof value === "number" ? value : 0);
        }, 0);
    }

    public getTotalByAcumColumn(column: Column, indexList: number[]) {
        return this.data.reduce((acc, row, currentIndex) => {
            if (indexList.includes(currentIndex)) {
                const value = this.getRowValue({ row, header: column });
                return acc + (typeof value === "number" ? value : 0);
            }

            return acc;

        }, 0);
    }

    public getTotalByRow(columns: string[], rowIndex: number) {
        return columns.reduce((acc, column) => {
            if  (this.data[rowIndex] && this.data[rowIndex][column]) {
                const value = this.data[rowIndex][column];
                return acc + (typeof value === "number" ? value : 0);
            }

            return acc

        }, 0);
    }

    public sortData(column: string, order: 'asc' | 'desc') {
        this.data.sort((a, b) => {
            if (a[column] < b[column]) {
                return order === 'asc' ? -1 : 1;
            }
            if (a[column] > b[column]) {
                return order === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }


}