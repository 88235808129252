import React, {FormEvent, useEffect, useState} from "react";
import {fields} from "./config";
import {Field} from "../../../../types/Field";
import CreateFieldPreview from "../CreateFieldModal/CreateFieldPreview";
import ModalComponent from "../ModalComponent/ModalComponent";
import {useDispatch} from "react-redux";
import {actionsModal, ModalTypes} from "../../../../store/Modal/Slice";
import useTranslate from "../../../../hooks/useTranslate";
import {Col, Row} from "reactstrap";
import {ProcessModel, Sequence} from "../../../../types/models/processModel/Process";
import {
  createOrUpdateProcessModelService,
  getModelProcessDataFields
} from "../../../../services/backend/ProcessService";
import {ACTION_TYPES, JSONtoString, toJSON, uuidV4} from "../../../../utils";
import IField from "../../../../types/FormBuilder/IField";
import {StringOption} from "../../../../types/Select";
import SuggestionRuleSearchComponent from "../SuggestionRuleSearchComponent";

export interface IProps {
  open: boolean;
  data: IPropsUpsertSequenceModal;

}

export interface IPropsUpsertSequenceModal {
  type: ModalTypes.UPSERT_SEQUENCE_MODAL;
  sequence?: Sequence;
  currentProcessModel: ProcessModel;
  onFinish: (process: ProcessModel) => void;
}


const UpsertSequenceModal = (props: IProps) => {

  const [fieldsInput, setFieldsInput] = useState<Field[]>([]);
  // const {forms} = useSelector((state: RootState) => state.FormReducer);
  const [sequence, setSequence] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {t} = useTranslate("common");
  const taskOptions = props.data.currentProcessModel.tasks.map(task => ({
    label: `Task - ${task.id} ${task.name}`,
    value: `Task - ${task.id}`,
    data: task
  }));
  const eventOptions = props.data.currentProcessModel.events.map(event => ({
    label: `Event - ${event.id} ${event.name}`,
    value: `Event - ${event.id}`,
    data: event
  }));
  const [processDataFields, setProcessDataFields] = useState<StringOption[]>([]);
  const [focusField, setFocusField] = useState("");

  const gatewayOptions = props.data.currentProcessModel?.gateways?.map(gateway => ({
    label: `Gateway - ${gateway.id} ${gateway.name}`,
    value: `Gateway - ${gateway.id}`,
    data: gateway
  })) ?? [];

  const tasksEvents = [...taskOptions, ...eventOptions, ...gatewayOptions]

  const options: any = {
    to_item_id: tasksEvents,
    from_item_id: tasksEvents,
    action_type: ACTION_TYPES.map(act => ({...act, label: t(act.label)})),
    inline: [{label: "Yes", value: true}, {label: "No", value: false}],
    skip_required_fields: [{label: "Yes", value: true}, {label: "No", value: false}],
    ["manager_style.line_type"]: [
      {label: "Default", value: "default"},
      {label: "Step", value: "step"},
      {label: "Smooth step", value: "smoothstep"},
      {label: "Straight", value: "straight"},
    ]
  }
  useEffect(() => {

    function getOptVal(field: IField, val: any) {
      return options[field.name]?.find((opt: any) => {
        return opt.value === val || typeof opt.value === "string" && opt.value.includes(` ${val}`)
      }) ?? val
    }

    setFieldsInput(fields.map((field, i) => {
      let tt = (props.data.sequence ?? {}) as any;
      tt = {...tt, manager_style: toJSON(tt.manager_style)}
      setSequence((e: any) => {

        switch (field.name) {
          case "manager_style.line_type":
            let Line_type = tt?.manager_style?.line_type ?? field.default_value;
            return {
              ...e,
              manager_style: {...e.manager_style, line_type: getOptVal(field, Line_type)}
            }

          default:
            let val = tt[field.name] ?? field.default_value;
            return {...e, [field.name]: getOptVal(field, val)}

        }
      });


      return {
        ...field,
        id: i,
      }
    }));
  }, [props.data]);

  const getObjectValue = (value: { value: any }) => {
    if (typeof value.value === "string") {
      return value.value.split("-")[1].trim();
    } else {
      return value.value;
    }
  }

  function getNewSeq(seq: Sequence | {}) {
    let newSeq: any = {...seq, ...sequence};
    fields.forEach(field => {


      switch (field.name) {

        case "manager_style.line_type":
          newSeq.manager_style = JSONtoString({
            ...sequence.manager_style,
            line_type: sequence.manager_style.line_type.value
          });
          break

        default:
          const value = sequence[field.name];
          const tt = typeof value;
          newSeq[field.name] = (tt === "object" ? getObjectValue(value) : value) ?? "";
          break;
      }
    });
    return newSeq as Sequence;
  }

  async function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let newProcessModel = {...props.data.currentProcessModel};

    setLoading(true);
    if (props.data.sequence?.id) {
      newProcessModel.sequences = newProcessModel.sequences.map(seq => {
        if (seq.id === props.data.sequence?.id) {
          return getNewSeq(seq);
        }
        return seq;
      });
    } else {
      let seqs = new Array(...newProcessModel.sequences ?? []);
      seqs.push(getNewSeq({
        id_manager: "SEQ_" + uuidV4(),
        manager_style: "{}"
      }));
      newProcessModel.sequences = seqs;
    }
    const res = await createOrUpdateProcessModelService(newProcessModel);
    if (res.success) {
      props.data.onFinish(res.item);
      toggle();
    } else {
      setLoading(false)
    }
  }

  const toggle = () => {
    dispatch(actionsModal.closeModal({type: ModalTypes.UPSERT_SEQUENCE_MODAL}))
  }

  const getItemType = (e: any) => {
    if (e.data.activity_type) {
      return e.data.activity_type
    } else if (e.data.gateway_type) {
      return "GATEWAY"
    } else {
      return "EVENT"
    }
  }

  React.useEffect(() => {

    const getDataFields = async () => {
      if (props.data?.sequence?.process_id) {
        const response = await getModelProcessDataFields(props.data.sequence.process_id)
        if (response?.success) {
          const items = response.items?.map((item: { label: string, name: string }) => ({
            label: item.label,
            value: item.name
          }))
          setProcessDataFields(items)
        } else {
        }
      }
    }

    getDataFields()
  }, [props.data]);


  const getSuggestionList = (field: IField, id: number) => {
    if (field.name === "rule" && focusField === "rule") {
      return <SuggestionRuleSearchComponent  value={sequence[field.name]} id={id}
                                            setValue={value => setSequence((sequence: Sequence) => ({
                                              ...sequence,
                                              [field.name]: value
                                            }))}
                                            options={processDataFields}/>
    }

    return null
  }

  function onChangeValueSequence(e: any, field: IField) {
    switch (field.name) {

      case "from_item_id":
        setSequence({
          ...sequence,
          [field.name]: e,
          from_item_type: getItemType(e)
        });
        break;

      case "to_item_id":
        setSequence({
          ...sequence,
          [field.name]: e,
          to_item_type: getItemType(e)
        });
        break;

      case "manager_style.line_type":
        setSequence({...sequence, manager_style: {...sequence.manager_style, line_type: e}});
        break;

      default:
        setSequence({...sequence, [field.name]: e});
        break;
    }
  }

  function getValue(field: IField) {

    switch (field.name) {
      case "manager_style.line_type":
        return sequence.manager_style.line_type;
      default:
        return sequence[field.name];
    }
  }

  return (
    <ModalComponent
      isLoading={loading}
      size="lg"
      title={props.data.sequence?.id ? t("update") : t("new")}
      isOpen={props.open}
      type="submit"
      form="formUpsertModal"
      toggle={toggle}>
      <form id="formUpsertModal" onSubmit={onSubmit}>
        <Row>
          {fieldsInput.map((field, index) => {
            return (
              <Col key={index} md={6} sm={12}>
                <CreateFieldPreview
                  options={options[field.name]}
                  onChange={e => onChangeValueSequence(e, field)}
                  onFocus={() => setFocusField(field.name)}
                  value={getValue(field)}
                  field={field}/>
                {field.name === "rule" && getSuggestionList(field, index)}
              </Col>
            )
          })}
        </Row>
      </form>
    </ModalComponent>
  )
}

export default UpsertSequenceModal;
