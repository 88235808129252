import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FormState, FormStates} from './Types';
import Form from '../../types/FormType';
import {plainToClass} from 'class-transformer';
import DataList from '../../types/FormBuilder/DataList';
import {Field} from '../../types/Field';
import {toMap} from "../../utils";

export const initialStateForm: FormState = {
  forms: [],
  formsMap: {},
  currentForm: plainToClass(Form, {
    id: 1,
    name: '',
    fields: [],
    domain: 0,
  }),
  parentFormId: null,
  fields: [],
  state: FormStates.IDLE,
  listProviders: []
};

const formReducer = createSlice({
  initialState: initialStateForm,
  name: "form",
  reducers: {
    getForms(state) {
      state.state = FormStates.FORMS_PENDING;
    },
    setForms: (state, action: PayloadAction<Form[]>) => {
      state.forms = action.payload;
      state.formsMap = toMap(action.payload, "id")
      state.state = FormStates.FORMS_RESOLVED;
    },
    getFormById(state, action: PayloadAction<number>) {
      state.state = FormStates.FORM_PENDING;
    },
    deleteFormById(state, action: PayloadAction<number>) {
      state.state = FormStates.FORM_PENDING;
    },
    setCurrentForm(state, action: PayloadAction<Form>) {
      state.currentForm = action.payload;
      state.state = FormStates.FORM_RESOLVED;
    },
    upsertForm(state, action: PayloadAction<{ name: string, id?: number }>) {
      state.state = FormStates.FORM_PENDING;
    },
    upsertField(state, action: PayloadAction<{ formId: number, field: Field }>) {
      state.state = FormStates.FIELD_PENDING;
    },
    setCurrentField(state, action: PayloadAction<Field | null>) {
      state.currentField = action.payload;
    },
    deleteFieldById(state, action: PayloadAction<{ formId: number, fieldId: number }>) {
      state.state = FormStates.FIELD_PENDING;
    },
    getListProver(state) {
      state.state = FormStates.LIST_PROVIDER_PENDING;
    },
    setListProvider(state, action: PayloadAction<DataList[]>) {
      state.state = FormStates.LIST_PROVIDER_RESOLVED;
      state.listProviders = action.payload;
    },
    updateFields(state, action: PayloadAction<{ sourceIndex: number, destinationIndex: number }>) {
      let form = Object.assign({}, state.currentForm);

      if(!form.fields.some(f => f.field_type === "FORM_GROUP")) {
        form.fields = mapAndSortFields(form.fields, action.payload.sourceIndex, action.payload.destinationIndex);
      } else {
        const childrens = extractChildren(form.fields.filter(f => f.field_type === "FORM_GROUP"));
        const idGroups = childrens.map(f => f.field_id);
        const fieldsSubGroups = form.fields.filter(f => idGroups.includes(f.id));
        const fieldsFilter = form.fields.filter(f => !idGroups.includes(f.id));
        const sortFilter = mapAndSortFields(fieldsFilter, action.payload.sourceIndex, action.payload.destinationIndex);
        form.fields = [...sortFilter, ...fieldsSubGroups].map((f, i) => {
          f.sort_index = i;
          return f;
        });
      }
      state.currentForm = form;
    },
    cloneForm(state, action: PayloadAction<Form>) {
      state.state = FormStates.CLONING_FORM;
    },
    changeState(state, action: PayloadAction<FormStates>) {
      state.state = action.payload;
    }
  }
});

const extractChildren = (fields: Field[]): any[] => {
  let flatChildren: any[] = [];
  const traverse = (items: Field[]) => {
    items.forEach((item) => {
      if (item.format_rules_definition?.fields && (item.format_rules_definition?.fields as any as Field[])?.length) {
        flatChildren = flatChildren.concat(item.format_rules_definition?.fields);
        traverse(item.format_rules_definition?.fields as any as Field[]);
      }
    });
  };

  traverse(fields);
  return flatChildren;
};

const mapAndSortFields = (fields: Field[], sourceIndex: number, destinationIndex: number) => {
  return fields.map((f: any, i: number) => {
    if (i === sourceIndex) {
      f.sort_index = destinationIndex;
    } else if (i === destinationIndex) {
      f.sort_index = sourceIndex;
    } else {
      f.sort_index = i;
    }
    return f;
  }).sort((a: any, b: any) => a.sort_index - b.sort_index);
}

export const actionsForm = formReducer.actions;
export default formReducer.reducer;
