import {get, post} from '../network';
import Axios from "axios";
import {downloadFileUtil} from "../utils";

export const getParseToken = (token: string) => {
  return !token?.toLowerCase().startsWith("bearer") ? `Bearer ${token}` : token;
}

export function getFileData(key: string) {
  return get(`/api/content/v1/folder${key ? "/" + key : ""}`);
}

export function createFolder({parent_key, name}: { parent_key: string, name: string}) {
  return post(`/api/content/v1/folder/name`, {parent_key, name});
}

export function getFilesDataByKeys(keys: string[]) {
  return post(`/api/content/v1/folder`, {keys});
}

export function getFile(key: string) {
  return get(`/api/content/v1/download/${key}`).then(res => ({...res, file_key: key}));
}

export function deleteSbxFileService(key: string) {
  return post(`/api/content/v1/delete/${key}`);
}

// /api/content/v1/delete?key=b9bc5bf3-a7c8-4e59-905e-7cda72224630
export function downloadFileService(url: string, name: string) {
  return Axios.get(url, {responseType: "blob"}).then(file => downloadFileUtil(file.data, name));
}

export function getFileService(url: string) {
  return Axios.get(url, {responseType: "blob"}).then(file => file.data);
}


export function getAccountFilesByKey(key: string) {
  return get(`/api/v2/wf/account/${key}/files`);
}

export function findByModelService(params: any) {
  return post('/api/data/v1/row/model/find_all', params);
}